import Prismic from "@prismicio/client";

export const apiEndpoint = "https://merlet.cdn.prismic.io/api/v2";
export const accessToken = "";

// Client method to query documents from the Prismic repo
export const Client = (req = null) =>
  Prismic.client(apiEndpoint, createClientOptions(req, accessToken));

const createClientOptions = (req = null, prismicAccessToken = null) => {
  const reqOption = req ? { req } : {};
  const accessTokenOption = prismicAccessToken
    ? { accessToken: prismicAccessToken }
    : {};
  return {
    ...reqOption,
    ...accessTokenOption,
  };
};

export const linkResolver = (doc) => {
  // URL for a category type
  if (doc.type === "brand") {
    return `/merken/${doc.uid}`;
  }

  // URL for a product type
  if (doc.type === "products") {
    switch (doc.uid) {
      case "maathemden":
        return `/maathemden`;
      case "werkkleding":
        return `/werkkleding`;
      case "veiligheidsschoenen":
        return `/veiligheidsschoenen`;
      case "promotiekleding":
        return `/promotiekleding`;
      case "zeefdruk":
        return `/promotiekleding/zeefdruk`;
      case "borduren":
        return `/promotiekleding/borduren`;
      case "textieltransfer":
        return `/promotiekleding/textieltransfer`;
      case "promotiemateriaal":
        return `/promotiemateriaal`;
      case "pbm":
        return `/pbm`;
      default:
        break;
    }
    // return `/product/${doc.uid}`;
  }

  if (doc.type === "contact") {
    return `/contact`;
  }

  if (doc.type === "atelier") {
    return `/atelier`;
  }

  // URL for a page type
  if (doc.type === "page") {
    return `/${doc.uid}`;
  }

  // Backup for all other types
  return "/";
};
